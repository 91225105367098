import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { adminLogin, adminVerification } from "../../lib/api/auth";
import { validateEmail } from "../../lib/helpers/validations";
import {
  disableButton,
  enableButton,
  setStep,
  setError,
} from "../../redux/actions/adminLogin";
import { showLoader, hideLoader } from "../../redux/actions/app";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";

import MultitenancyLogoPicker from 'multitenancy/multitenancyLogoPicker';

import "./styles.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const AdminLogin = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const isButtonDisabled = useSelector(
    (state) => state.adminLogin.isButtonDisabled
  );
  const currentStep = useSelector((state) => state.adminLogin.currentStep);
  const error = useSelector((state) => state.adminLogin.error);
  const loading = useSelector((state) => state.adminLogin.loading);
  const consumerEmail = useSelector((state) => state.forgotPassword.email);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [email, setEmail] = useState(consumerEmail || "");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  let query = useQuery();

  if (query.size > 0) {
    window.location.replace("/admin");
  }

  const handleLogIn = async (e) => {
    e.preventDefault();
    dispatch(setError(""));
    if (!email || !password) {
      dispatch(setError("Some fields are empty."));
      alert.error("Some fields are empty.");
      setIsEmailValid(false);
      return;
    }
    dispatch(showLoader());
    dispatch(disableButton());
    const data = await adminLogin({
      email,
      password,
    });
    if (data.success === "True") {
      dispatch(setStep("2"));
      dispatch(enableButton());
      dispatch(hideLoader());
    } else {
      dispatch(setError(data.message));
      alert.error(data.message);
      dispatch(enableButton());
      dispatch(hideLoader());
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setError("");
    if (!verificationCode) {
      dispatch(setError("Empty verification code."));

      return;
    }
    dispatch(disableButton());
    dispatch(showLoader());
    const data = await adminVerification({
      email,
      verification_code: verificationCode,
    });

    if (data.success === "True") {
      window.location.replace("/admin/consumers");
    } else {
      dispatch(setError(data.message));
      dispatch(enableButton());
      dispatch(hideLoader());
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value.replace(/\s/g, ''); // Remove all spaces
    setPassword(newPassword);
    if (newPassword === "") {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  // Handle Back button click
  const handleGoBack = () => {
    dispatch(setStep("1"));
    dispatch(setError(""));
  };

  const gotToForgotPassword = () => {
    window.location.replace("/forgot-password");
  };

  const getHeaderText = () => {
    if (currentStep === "1") {
      return "Admin Panel";
    } else {
      return "Verification Code";
    }
  }

  return (
    <div className="admin-login">
      <div className="admin-login__container">
        <div className="logo">
          <MultitenancyLogoPicker />
        </div>
        <h2 className="heading heading-1">{getHeaderText()}</h2>
        {currentStep === "2" ? 
          <div className="subtitle">Enter the verification code sent to <span>{email}</span></div>
        : null}
        {currentStep === "1" &&
          (loading ? (
            <LoadingSpinner middleFixed={true}/>
          ) : (
            <StepOne
              error={error}
              email={email}
              handleEmailChange={handleEmailChange}
              isEmailValid={isEmailValid}
              password={password}
              handlePasswordChange={handlePasswordChange}
              gotToForgotPassword={gotToForgotPassword}
              handleLogIn={handleLogIn}
              isButtonDisabled={isButtonDisabled}
              isPasswordValid={isPasswordValid}
            />
          ))}

        {currentStep === "2" &&
          (loading ? (
            <LoadingSpinner middleFixed={true}/>
          ) : (
            <StepTwo
              handleGoBack={handleGoBack}
              error={error}
              verificationCode={verificationCode}
              handleVerificationCodeChange={handleVerificationCodeChange}
              handleVerification={handleVerification}
              isButtonDisabled={isButtonDisabled}
            />
          ))}
      </div>
    </div>
  );
};
