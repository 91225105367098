const BankRoutNumbValid = require('bank-routing-number-validator');

export const isZipCodeValid = (zip) => {
  const regExp = /[0-9]{5}/g;

  return regExp.test(zip);
};

export const isPhoneNumberValid = (number) => {
  const regExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g;

  return regExp.test(number);
};

export const isEmailValid = (email) => {
  if (email === "") return true;

  const regExp = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/; // eslint-disable-line

  return regExp.test(email);
};

export const validateEmail = (email) => {
  const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/; // eslint-disable-line
  return re.test(String(email).toLowerCase());
};

export const hasLeadingZero = (value) => {
  if (
    value.slice(0, 1) === "0" &&
    value.indexOf(".") !== 1 &&
    value.length !== 1
  ) {
    return true;
  }
  return false;
};


export const validRoutingNumber = (number) => {
  if (number.length === 9) {
    if (number == 999999992) {
      return true;
    } else {
      return BankRoutNumbValid.ABARoutingNumberIsValid(number);
    }
  } else {
    return false;
  }
}

export const validAccountNumber = (number) => {
  if (number.length <= 17 && number.length !== 0) {
    return true;
  } else {
    return false;
  }
}
