import { SHOW_LOADER, HIDE_LOADER, SET_TENANT_SETTINGS } from "../types";
import { getTenantSettings } from "lib/api/common";

export function showLoader() {
  return { type: SHOW_LOADER };
}

export function hideLoader() {
  return { type: HIDE_LOADER };
}

export function fetchTenantSettings() {
  return async (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    try {
      const response = await getTenantSettings();
      if (response.success === "True") {
        const tenantSettings = response.data;

        dispatch({ type: SET_TENANT_SETTINGS, payload: tenantSettings });
      } else {
        console.error(
          "Failed to fetch tenant settings, status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching tenant settings:", error);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };
}
