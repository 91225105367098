import moment from "moment";

import {
  FETCH_ACTIVE_TAGS,
  FETCH_EXTERNAL_TAGS,
  FETCH_CONSUMER_NOTES,
  HIDE_LOADER,
  SET_NOTES_DATES_RANGE,
  SET_SHOW_PER_PAGE,
  SHOW_LOADER,
  SHOW_DATEPICKER,
  HIDE_DATEPICKER,
} from "../types";

const tomorrow = moment().add(1,'days');

const initialState = {
  loading: true,
  notesData: {},
  activeTags: null,
  externalTags: null,
  datesRange: {
    date_from: new Date("January 1, 2020 00:00:00"),
    date_to: tomorrow._d,
  },
  showPerPage: 10,
  isDatePickerOpen: false,
};

export const consumerNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_CONSUMER_NOTES:
      return { ...state, notesData: action.payload };
    case FETCH_ACTIVE_TAGS:
      return { ...state, activeTags: action.payload };
    case FETCH_EXTERNAL_TAGS:
      return { ...state, externalTags: action.payload };
    case SET_NOTES_DATES_RANGE:
      return { ...state, datesRange: action.payload };
    case SET_SHOW_PER_PAGE:
      return { ...state, showPerPage: action.payload };
    case SHOW_DATEPICKER:
      return { ...state, isDatePickerOpen: true };
    case HIDE_DATEPICKER:
      return { ...state, isDatePickerOpen: false };
    default:
      return state;
  }
};
