import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import { getCommCount } from "lib/api/common";

import { SeeDetailsModal } from "./SeeDetailsModal";
import { formattedMoney } from "lib/helpers/formatters";

import { useTranslation } from "react-i18next";
import { getTenant, tenant } from "multitenancy/multitenancyGetTenant";

import "./style.scss";
import { getContentBlock } from "lib/api/consumer";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      display: "none",
    },
  },
}));

export const ConsumerOverview = ({ overviewInfo, isAdmin }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const classes = useStyles();
  const [seeDetailsModal, setSeeDetailsModal] = useState(false);
  const toggleSeeDetails = () => setSeeDetailsModal(!seeDetailsModal);
  const [content, setContent] = useState();
  const [commCount, setCommCount] = useState();

  const openSeeDetails = () => {
    toggleSeeDetails();
  };

  useEffect(() => {
    if (id) {
      getCommValue();
    }
    getContent();
  }, []);

  const getCommValue = async () => {
    const response = await getCommCount(overviewInfo.referenceNumber);
    if (response.success === "True") {
      setCommCount(response.comm_count);
    }
  };

  const getContent = async () => {
    const response = await getContentBlock("consumer-overview");
    if (response.success === "True") {
      const contentBody = JSON.parse(response.data?.body);
      setContent(contentBody);
    }
  };

  const checkStatus = () => {
    if (!overviewInfo.loan_id) {
      return !(
        overviewInfo.status === "on_hold" ||
        overviewInfo.status === "recalled" ||
        overviewInfo.status === "paid_off"
      );
    }
    return true;
  };

  const renderOverviewBlock = () => {
    if (getTenant() === tenant.CONSUEGRA && "next_due" in overviewInfo) {
      return (
        <div className="body">
          <div className="block-container">
            <span className="label">{t("Account Number")}</span>
            <span className="value">{overviewInfo.referenceNumber}</span>
          </div>
          <div className="block-container">
            <span className="label">{t("Next Payment Due Date")}</span>
            <span className="value">{overviewInfo.next_due}</span>
          </div>
          <div className="block-container">
            <span className="label">{t("Next Payment Amount")}</span>
            <span className="value">
              ${formattedMoney(overviewInfo.ptp_payment_amount)}
            </span>
          </div>
          <div className="block-container">
            <span className="label">{t("Remaining Balance")}</span>
            <span className="value">
              ${formattedMoney(overviewInfo.ptp_balance)}
            </span>
          </div>
          <Button
            className="see-details-btn"
            id="seeDetails"
            onClick={() => openSeeDetails()}
          >
            {t("See Details")}
          </Button>
          <SeeDetailsModal
            content={content?.seeDetailsModal}
            toggle={toggleSeeDetails}
            modal={seeDetailsModal}
            info={overviewInfo}
            isAdmin={isAdmin}
          />
        </div>
      );
    } else {
      return (
        <div className="body">
          {overviewInfo.originalCreditor && (
            <div className="block-container">
              <span className="label">
                {content?.consumerOverview?.originalCreditor
                  ? t(content?.consumerOverview?.originalCreditor)
                  : t("Original Creditor")}
              </span>
              <span className="value">
                {content?.consumerOverview?.originalCreditor ===
                "Current Creditor"
                  ? overviewInfo?.currentCreditor
                  : overviewInfo.originalCreditor}
              </span>
            </div>
          )}
          {isAdmin ? (
            <>
              {overviewInfo.status !== "active" && (
                <div className="block-container">
                  <span className="label">SOR Balance</span>
                  <span className="value">
                    ${formattedMoney(overviewInfo.currentBalance)}
                  </span>
                </div>
              )}
              {checkStatus() && (
                <div className="block-container">
                  <span className="label">
                    {overviewInfo.status === "active"
                      ? "Current Balance"
                      : "Plan Balance"}
                  </span>
                  <span className="value">
                    ${formattedMoney(overviewInfo.plan_balance)}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className="block-container">
              <span className="label">{t("Current Balance")}</span>
              <span className="value">
                $
                {overviewInfo.is_open_ended
                  ? formattedMoney(overviewInfo.currentBalance)
                  : formattedMoney(overviewInfo.plan_balance)}
              </span>
            </div>
          )}
          {overviewInfo.original_settlement_amount && (
            <div className="block-container">
              <span className="label">{t("Settlement Amount")}</span>
              <span className="value">
                {overviewInfo.original_settlement_amount}
              </span>
            </div>
          )}
          <div className="block-container">
            <span className="label">
              {content?.consumerOverview?.referenceNumber
                ? t(content?.consumerOverview?.referenceNumber)
                : t("Reference Number")}
            </span>
            <span className="value">{overviewInfo.referenceNumber}</span>
          </div>
          <div className="block-container">
            <span className="label">
              {content?.consumerOverview?.accountNumber
                ? t(content?.consumerOverview?.accountNumber)
                : t("Account Number")}
            </span>
            <span className="value">
              {overviewInfo.originalAccountNumber.slice(-4)}
            </span>
          </div>
          {isAdmin ? (
            <div className="block-container">
              <span
                className={`label ${commCount >= 5 ? "comm-count" : null} `}
              >
                Comm Count
              </span>
              <span
                className={`value capital ${
                  commCount >= 5 ? "comm-count" : null
                }`}
              >
                {commCount ? commCount : "-"}
              </span>
            </div>
          ) : null}
          <Button
            className="see-details-btn"
            id="seeDetails"
            onClick={() => openSeeDetails()}
          >
            {t("See Details")}
          </Button>
          <SeeDetailsModal
            content={content?.seeDetailsModal}
            toggle={toggleSeeDetails}
            modal={seeDetailsModal}
            info={overviewInfo}
            isAdmin={isAdmin}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="consumer-overview-container">
        <div className="title">{t("Overview")}</div>
        {renderOverviewBlock()}
      </div>
      <Accordion
        className="mobile-accordion-container"
        classes={{
          root: classes.MuiAccordionroot,
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="title">{t("Overview")}</div>
        </AccordionSummary>
        <AccordionDetails>{renderOverviewBlock()}</AccordionDetails>
      </Accordion>
    </>
  );
};
