import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useAlert } from "react-alert";

import {
  addBankAccount,
  submitPayment,
  submitOneTimePayment,
  generateNACHA,
} from "lib/api/common";

import { setRequest } from "redux/actions/setupPlan";
import { fetchConsumerProfile } from "redux/actions/profile";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";

import { FinalDetailsModal } from "../FinalDetailsModal";

import { ReceiptModal } from "components/ReceiptModal";
import { accountNumberLastFour } from "lib/helpers/other";
import { AuthAgreementPhoneMultiPay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneMultiPay";
import { AuthAgreementPhoneSinglePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneSinglePay";
import { NACHAPhoneMultiModalData } from "lib/common/NACHAModalData";
import { NACHAPhoneSingleModalData } from "lib/common/NACHAModalData";
import { ChangePaymentSuccess } from "ConsumerPage/PaymentsHistoryContainer/ExtraPaymentFlow/SelectPaymentMethodModal/ChangePaymentSuccess";

import "./style.scss";

export const NewBankAccountModal = ({
  modal,
  toggle,
  parentModal,
  parentToggle,
  debt,
  dateValue,
  selectedPlan,
  possiblePaymentSchedule,
  paymentAmount,
  type,
  isChangePaymentMethod,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [accountType, setType] = useState("1");
  const [authMethod, setAuthMethod] = useState("web");
  const consumersData = useSelector((state) => state.profile.profileData);
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const isNextButtonDisabled = useSelector(
    (state) => state.setupPlan.isNextButtonDisabled
  );
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

  const [isFinalDetailsModalOpen, setIsFinalDetailsModalOpen] = useState(false);
  const toggleFinalDetailsModal = () =>
    setIsFinalDetailsModalOpen(!isFinalDetailsModalOpen);

  const profileInfo = useSelector((state) => state.profile.profileData.profile);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [isShowingModalAch, setIsShowingModal] = useState(false);

  const [changePaymentSuccessModal, setChangePaymentSuccessModal] =
    useState(false);
  const toggleChangePaymentSuccessModal = () =>
    setChangePaymentSuccessModal(!changePaymentSuccessModal);
  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number || ""
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours || "");
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name || ""
  );
  useEffect(() => {
    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        name_on_check: consumersData.profile?.full_name,
        email: consumersData.profile?.email,
        data_id: debt.referenceNumber,
      })
    );
  }, [consumersData]);

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  };

  const toggleModal = () => {
    toggle();
    parentToggle();
  };

  const handleSetAccountPaymentRequest = (e) => {
    e.persist();

    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: debt.debt_id,
        account_type: accountType,
        institution_number: 803,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const handleAddNewACH = async () => {
    const newBankAccountRequest = {
      ...accountPaymentRequest,
    };

    setLoading(true);
    setIsConfirmDisabled(true);

    const accountData = await addBankAccount({
      ...newBankAccountRequest,
      client_id: id,
      authorization: authMethod,
    });

    if (accountData.success === "True") {
      if (isChangePaymentMethod) {
        await generateNACHA({
          account_number: accountPaymentRequest.account_number,
          account_type: accountPaymentRequest.account_type,
          bank_name: accountPaymentRequest.bank_name,
          agreement_timestamp: new Date(),
          email: profileInfo.email,
          client_id: id,
          routing_number: accountPaymentRequest.routing_number,
          name_on_check: accountPaymentRequest.name_on_check,
          payment_frequency: "Regular",
          data_id: debt.referenceNumber,
        });
        toggleChangePaymentSuccessModal();
        return;
      }

      if (type === "OTP") {
        makeOTPPayment(newBankAccountRequest, accountData);
      } else if (type === "PLAN") {
        makeSubmitPayment(newBankAccountRequest, accountData);
      }
      setSelectedPaymentObject(newBankAccountRequest);
    } else {
      setLoading(false);
      setIsConfirmDisabled(false);
      alert.error(accountData.message);
    }
  };

  const makeOTPPayment = async (newBankAccountData, accountData) => {
    const request = {
      client_id: id,
      account_number: newBankAccountData?.account_number,
      account_type: "1",
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      bank_name: newBankAccountData.bank_name,
      card_id: accountData.account_id,
      debt_id: debt.debt_id,
      // email: profileInfo.email,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: newBankAccountData.routing_number,
    };

    const data = await submitOneTimePayment({ ...request, client_id: id });
    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const makeSubmitPayment = async (newBankAccountData, accountData) => {
    const request = {
      client_id: id,
      account_number: newBankAccountData?.account_number,
      account_type: "1",
      agreement_timestamp: new Date(),
      bank_name: newBankAccountData.bank_name,
      debt_id: debt.debt_id,
      // email: profileInfo.email,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: newBankAccountData.routing_number,
      plan_id: selectedPlan.id,
      start_day: dateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
      card_id: accountData.account_id,
    };

    const data = await submitPayment({ ...request, client_id: id });
    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const renderNACHABlock = () => {
    if (
      (!paymentAmount &&
        possiblePaymentSchedule?.possible_payment_info?.payments_number > 1) ||
      isChangePaymentMethod
    ) {
      return (
        <div className="ach-nach-con">
          <AuthAgreementPhoneMultiPay
            accountType={accountType === "1" ? "Checking" : "Savings"}
            accountNumber={accountNumberLastFour(
              accountPaymentRequest.account_number
            )}
            consumerName={
              accountPaymentRequest.name_on_check ||
              consumersData.profile?.full_name
            }
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            isChangingPaymentMethod={isChangePaymentMethod}
            startDate={
              possiblePaymentSchedule?.possible_payment_info.start_date
            }
            endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
            consumerState={consumersData.profile?.state}
            numberOfPayments={
              possiblePaymentSchedule?.possible_payment_info?.payments_number
            }
            payment_frequency={selectedPlan?.payment_frequency}
            start_amount={
              possiblePaymentSchedule?.possible_payment_info.start_amount
            }
            end_amount={
              possiblePaymentSchedule?.possible_payment_info.end_amount
            }
          />
        </div>
      );
    } else {
      return (
        <div className="ach-nach-con">
          <AuthAgreementPhoneSinglePay
            accountType={accountType === "1" ? "Checking" : "Savings"}
            accountPaymentRequest={accountPaymentRequest}
            accountNumber={accountNumberLastFour(
              accountPaymentRequest.account_number
            )}
            authorizationSignature={consumersData.profile?.full_name}
            paymentAmount={
              paymentAmount
                ? paymentAmount
                : possiblePaymentSchedule?.possible_payment_info.per_month
            }
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            consumerState={consumersData.profile?.state}
            startDate={
              possiblePaymentSchedule?.possible_payment_info.start_date
            }
          />
        </div>
      );
    }
  };

  const renderReceiptModal = () => {
    if (
      (!paymentAmount &&
        possiblePaymentSchedule?.possible_payment_info?.payments_number > 1) ||
      isChangePaymentMethod
    ) {
      return (
        <ReceiptModal
          data={NACHAPhoneMultiModalData(
            companyName,
            phone,
            hours,
            possiblePaymentSchedule?.possible_payment_info.start_date,
            possiblePaymentSchedule?.possible_payment_info.end_date,
            accountPaymentRequest.name_on_check,
            accountType,
            accountNumberLastFour(accountPaymentRequest.account_number),
            isChangePaymentMethod,
            consumersData.profile?.state,
            possiblePaymentSchedule?.possible_payment_info.payments_number,
            selectedPlan?.payment_frequency,
            possiblePaymentSchedule?.possible_payment_info.start_amount,
            possiblePaymentSchedule?.possible_payment_info.end_amount
          )}
          setShowModal={setIsShowingModal}
        />
      );
    } else {
      return (
        <ReceiptModal
          data={NACHAPhoneSingleModalData(
            companyName,
            phone,
            hours,
            accountPaymentRequest.name_on_check,
            accountType,
            accountNumberLastFour(accountPaymentRequest.account_number),
            paymentAmount
              ? paymentAmount
              : possiblePaymentSchedule?.possible_payment_info.per_month,
            consumersData.profile?.state,
            possiblePaymentSchedule?.possible_payment_info.start_date,
            null
          )}
          setShowModal={setIsShowingModal}
        />
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        onOpened={closeParentModal}
        scrollable={true}
        className="new-bank-account-modal"
      >
        {loading ? <LoadingSpinner middleFixed={true} /> : null}
        <ModalBody>
          <div className="mod-header">
            <span>Payment Details</span>
            <button className="close-modal-btn" onClick={() => toggleModal()}>
              <Close />
            </button>
          </div>
          <div className="body-block">
            <form>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="account_type"
                >
                  Authorization Method
                </label>
                <Select
                  labelId="authorization"
                  id="authorization"
                  name="authorization"
                  onChange={setAuthMethodFunc}
                  input={
                    <InputBase
                      value={authMethod}
                      className="input input-medium"
                    />
                  }
                >
                  <MenuItem value={"web"}>Web</MenuItem>
                  <MenuItem value={"verbal"}>Verbal</MenuItem>
                  <MenuItem value={"written"}>Written</MenuItem>
                </Select>
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="account_type"
                >
                  Account Type
                </label>
                <Select
                  labelId="type"
                  id="account_type"
                  name="account_type"
                  onChange={handleTypeChange}
                  input={
                    <InputBase
                      value={accountType}
                      className="input input-medium"
                    />
                  }
                >
                  <MenuItem value={"1"}>Checking</MenuItem>
                  <MenuItem value={"2"}>Savings</MenuItem>
                </Select>
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="name_on_check"
                >
                  Name On Check
                </label>
                <input
                  type="text"
                  className="input input-medium"
                  name="name_on_check"
                  id="name_on_check"
                  onChange={handleSetAccountPaymentRequest}
                  defaultValue={accountPaymentRequest.name_on_check}
                />
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="routing_number"
                >
                  Routing Number
                </label>
                <input
                  type="number"
                  className="input input-medium"
                  id="routing_number"
                  name="routing_number"
                  onChange={handleSetAccountPaymentRequest}
                  value={accountPaymentRequest.routing_number}
                />
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="account_number"
                >
                  Account Number
                </label>
                <input
                  type="number"
                  className="input input-medium"
                  name="account_number"
                  id="account_number"
                  onChange={handleSetAccountPaymentRequest}
                  value={accountPaymentRequest.account_number}
                />
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="bank_name"
                >
                  Bank Name
                </label>
                <input
                  type="text"
                  className="input input-medium"
                  id="bank_name"
                  name="bank_name"
                  onChange={handleSetAccountPaymentRequest}
                  value={accountPaymentRequest.bank_name}
                />
              </div>
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="bank_name"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="input input-medium"
                  id="email"
                  name="email"
                  defaultValue={accountPaymentRequest.email}
                  onChange={handleSetAccountPaymentRequest}
                />
              </div>
            </form>
            <div className="mod-footer">
              <CustomButton
                text="Back"
                onClickHandler={() => toggleModal()}
                optionalClass="cancel-btn"
              />
              <CustomButton
                text="Confirm Payment"
                onClickHandler={() => handleAddNewACH()}
                disabled={isNextButtonDisabled || isConfirmDisabled}
                optionalClass="confirm-btn"
              />
            </div>
          </div>
          {renderNACHABlock()}
        </ModalBody>
      </Modal>
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}
      {isShowingModalAch && renderReceiptModal()}
      {isFinalDetailsModalOpen && (
        <FinalDetailsModal
          isModalOpen={isFinalDetailsModalOpen}
          parentModal={modal}
          parentToggle={toggle}
          startDate={possiblePaymentSchedule?.possible_payment_info.start_date}
          endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
          selectedPlan={selectedPlan}
          selectedPaymentObject={selectedPaymentObject}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      )}
    </>
  );
};
