import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formattedMoney } from "lib/helpers/formatters";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { InfoIcon } from "scss/multitenantIcons/multitenantIcons";

import { fetchPlanInfo } from "redux/actions/planInfo";
import { LoadingSpinner } from "components/LoadingSpinner";

import { OtherMasterCardIcon, OtherVisaIcon } from "components/icons/OtherIcons";
import amexSvg from "scss/images/amex.svg";
import discoverSVG from "scss/images/discover.svg";
import { EditPaymentPlanModal } from "./EditPaymentPlanModal";

import { fetchDefaultPaymentMethod } from "redux/actions/getDefaultPaymentMethod";

import "./style.scss";

export const EstablishedPlanInfo = ({debt}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const [currentDefaultPayment, setCurrentDefaultPayment] = useState();

  const planInfo = useSelector((state) => state.planInfo.planInfo?.data);
  const defaultPaymentMethod = useSelector((state) => state.getDefaultPaymentMethod.data);

  const [isEditPaymentPlanModalOpen, setEditPaymentPlanModalOpen] = useState(false);
  const toggleEditPaymentPlan = () => setEditPaymentPlanModalOpen(!isEditPaymentPlanModalOpen);

  useEffect(() => {
    dispatch(fetchPlanInfo(debt.plan_id, id));
    dispatch(fetchDefaultPaymentMethod(id, debt.referenceNumber));
  }, []);

  useEffect(() => {
    if (defaultPaymentMethod) {
      setUIDefaultPaymentMethod();
    }
  }, [defaultPaymentMethod])

  const HTMLTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  }))(Tooltip);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const setUIDefaultPaymentMethod = async () => {

    if (defaultPaymentMethod.bank_account_details) {
      setCurrentDefaultPayment(defaultPaymentMethod.bank_account_details.AccountNumber);
      setLoading(false);
    } else if (defaultPaymentMethod.card_details) {
      setCurrentDefaultPayment(defaultPaymentMethod.card_details.CardName);
      setLoading(false);
    } else if (defaultPaymentMethod.manual_details.Manual) {
      setCurrentDefaultPayment("Mail/Wire");
      setLoading(false);
    } else if (defaultPaymentMethod.paypal_details) {
      setCurrentDefaultPayment("PayPal");
      setLoading(false);
    }
  };

  const renderCardInfo = () => {
    if (currentDefaultPayment === "Mail/Wire") {
      return "Mail/Wire"
    } else if (currentDefaultPayment === "PayPal") {
      return "PayPal"
    }  else if (currentDefaultPayment) {
      return `•••• ${currentDefaultPayment?.slice(-4)}`;
    }
  }

  const renderCardIcon = () => {

    const currentPayment = currentDefaultPayment?.slice(0, -5);
    if (currentPayment === "MASTERCARD") {
      return <OtherMasterCardIcon />
    }
    if (currentPayment === "VISA") {
      return <OtherVisaIcon />
    }
    if (currentPayment === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />
    }
    if (currentPayment === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />
    }
  }

  const openEditPaymentModal = () => {
    toggleEditPaymentPlan();
  }

  const planHeader = () => {
    return (
      (planInfo && planInfo.payments === 1 && "One Payment") ||
      (planInfo && planInfo.payment_frequency === "Weekly" && "Per Week") ||
      (planInfo && planInfo.payment_frequency === "Monthly" && "Per Month") ||
      (planInfo && planInfo.payment_frequency === "Bi-Weekly" && "Biweekly")
    );
  };

  return (
    <>
      <div className="established-plan-info" id="plan-info">
        {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
        <div className="title">
          Payment Plan
        </div>
        <div className="body">
          <div className="block-container">
            <span className="label">{planHeader()}</span>
            <span className="value">$ {formattedMoney(planInfo?.per_month)}</span>
          </div>
          <div className="block-container">
            <span className="label">Payments</span>
            <span className="value">{planInfo?.payments}</span>
          </div>
          <div className="block-container">
            <span className="label">Plan Type</span>
            <span className="value">{planInfo?.is_open_ended ? "Open" : "SIF/PIF"}</span>
          </div>
          <div className="block-container">
            <span className="label">Start Date</span>
            <span className="value">{moment(planInfo?.start_date).format("ll")}</span>
          </div>
          <div className="block-container">
            <span className="label">End Date</span>
            <span className="value">{moment(planInfo?.end_date).format("ll")}</span>
          </div>
          <div className="block-container">
            <span className="label">Payment Method</span>
            <span className="value">
              {renderCardIcon()}
              <span className="account-numbers">{renderCardInfo()}</span>
            </span>
          </div>
          <Button 
            className="edit-payment-method"
            onClick={() => openEditPaymentModal()}
          >
            Edit
          </Button>
          <div className="info-icon">
            <HTMLTooltip
              id="html-tooltip"
              open={open}
              title={
                <>
                  <div className="header">
                    <span>Details</span>
                    <button className="close-tooltip-btn" onClick={() => handleTooltipClose()}>
                      <Close />
                    </button>
                  </div>
                  <div className="main-block">
                    <div className="block-container">
                      <span className="label">Discount Percentage</span>
                      <span className="value">{planInfo?.discount_percentage}%</span>
                    </div>
                    <div className="block-container">
                      <span className="label">Discount</span>
                      <span className="value">$ {formattedMoney(planInfo?.discount)}</span>
                    </div>
                  </div>
                </>
              }
              placement="left-start"
            >
              <div onClick={handleTooltipOpen}>
                <InfoIcon /> 
              </div>
            </HTMLTooltip>
        </div>
        </div>
      </div>
      {/* {isEditPaymentPlanModalOpen && ( */}
        <EditPaymentPlanModal
          isModalOpen={isEditPaymentPlanModalOpen}
          toggle={toggleEditPaymentPlan}
          loanId={debt.loan_id}
          // selectedPlan={planInfo}
          debt={debt}
        />
      {/* )} */}
    </>
  )
}