import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { PageHeader } from "../../../../components/PageHeader";
import { ProfileBody } from "../../../../components/ProfileBody";
import { removeAgent } from "../../../../lib/api/admin";
import { fetchAgentProfile } from "../../../../redux/actions/profile";

import "./style.scss";

const SingleAgent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const profileData = useSelector((state) => state.profile.profileData);

  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );

  useEffect(() => {
    dispatch(fetchAgentProfile(id));
  }, [dispatch, id]);

  const handleRemoveAgent = async () => {
    await removeAgent({
      id: profileData.id,
      is_active: "False",
    });

    history.push("/admin/roles");
  };

  const helmetTitle =
    profileData?.full_name + ` – ${multitenancyCompanyShortName}`;

  return (
    <div className="single-agent profile-page-container">
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <PageHeader title="Roles name" backURL="/admin/roles" backTitle="Roles" />
      <div className="tab-container">
        <div className="tab-container__column">
          <div className="avatar-wrapper">
            <h2 className="heading heading-2">
              {profileData && profileData.full_name}
            </h2>
            <h6 className="heading heading-caption-bold">From Feb 28, 2020</h6>
            <button
              className="button button-transparent heading heading-body-bold"
              onClick={handleRemoveAgent}
            >
              Remove Agent
            </button>
          </div>
        </div>
        <ProfileBody profileData={profileData} profileType="agent" />
      </div>
    </div>
  );
};

export default SingleAgent;
