import moment from "moment";

export const isEquivalent = (obj1, obj2) => {
  // Create arrays of property names
  var obj1Props = Object.getOwnPropertyNames(obj1);
  var obj2Props = Object.getOwnPropertyNames(obj2);

  // If number of properties is different,
  // objects are not equivalent
  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (var i = 0; i < obj1Props.length; i++) {
    var propName = obj1Props[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const addHistoryType = (history, type) => {
  const newHistory = [];
  history.map((historyItem) => newHistory.push({ ...historyItem, type }));
  return newHistory;
};

export const accountNumberLastFour = (accountNumber) => {
  if (!accountNumber) return;
  return accountNumber.account_number?.length <= 4
    ? accountNumber
    : accountNumber.slice(accountNumber.length - 4, accountNumber.length);
};

export const nearestDate = (datesToBeChecked = [], dateToCheckFor) => {
  let nearestDate;

  datesToBeChecked.forEach((date) => {
    let diff = moment(date).diff(moment(dateToCheckFor), "days");
    if (diff > 0) {
      if (nearestDate) {
        if (moment(date).diff(moment(nearestDate), "days") < 0) {
          nearestDate = date;
        }
      } else {
        nearestDate = date;
      }
    }
  });

  return nearestDate;
};
