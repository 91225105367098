export const formatedConsumersData = (data) => {
  const formattedArray = [];

  data.map((item) => {
    formattedArray.push({
      id: item.client_id,
      fullName: `${item.full_name}`.trim(),
      SSN: item.ssn,
      cellPhone: item.cell_phone,
      address: item.address || "",
      dateOfBirth: item.dob || "",
      orginator: item.orginator,
      account_number: item.acc_no,
      reference: item.reference,
      amount: item.amount,
      status: item.debt_status,
    });

    return null;
  });

  return formattedArray;
};
