import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { ButtonLink } from "components/ui-kit/ButtonLink";
import { formattedMoney } from "lib/helpers/formatters";

export const AuthAgreementWebOnePay = ({
  accountType,
  setIsShowingModal,
  selectedPaymentAccount,
  paymentAmount,
  nearestScheduleDate,
}) => {
  const sum = localStorage.getItem("paymentSum");
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const tenantName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const isJavitch = tenantName === "JB LLC";
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name
  );
  const phoneNumber = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours);

  const accountNumberLastFour = () => {
    if (selectedPaymentAccount === "new") {
      return accountPaymentRequest.account_number?.length <= 4
        ? accountPaymentRequest.account_number
        : accountPaymentRequest.account_number?.slice(
            accountPaymentRequest.account_number?.length - 4,
            accountPaymentRequest.account_number?.length
          );
    } else {
      return selectedPaymentAccount.AccountNumber?.length <= 4
        ? selectedPaymentAccount.AccountNumber
        : selectedPaymentAccount.AccountNumber?.slice(
            selectedPaymentAccount.AccountNumber?.length - 4,
            selectedPaymentAccount.AccountNumber?.length
          );
    }
  };

  return (
    <div className="nacha-container">
      {isJavitch ? (
        <div className="nacha-text">
          <p>
            An authorization to conduct an ACH transfer may result in the
            withdrawal of funds from your account as soon as 24 hours from the
            time we receive your authorization; and
          </p>
          <p>
            You understand that your account will be debited on the Payment Date
            or the next business day following the Payment Date, should the
            Payment Date fall on a weekend or legal holiday; and
          </p>
          <p>
            You understand that your authorization may be revoked or cancelled
            by you, if we receive notice from you, not less than three business
            days in advance of the scheduled payment date. If you need more
            information about any such transaction, you should contact us as
            soon as possible to discuss your payment; and
          </p>
          <p>
            You understand that to revoke your consent, you must call us at 216-
            687-1199, or write us.
          </p>
        </div>
      ) : (
        <div className="nacha-text">
          {!sum && !paymentAmount ? (
            <>
              <p>
                I hereby authorize {companyName} to initiate an electronic debit
                withdrawal from my account with the Financial Institution.
                Furthermore, I assert that I am the owner or an authorized
                signer of this bank account.
              </p>
              <p>
                Payment date will show as:{" "}
                <span className="bold">
                  {moment(nearestScheduleDate).format("ll")}
                </span>
              </p>
            </>
          ) : (
            <>
              <p>
                I hereby authorize {companyName} to initiate an electronic debit
                withdrawal from my account with the Financial Institution
                indicated below in the amount of $
                {sum ? formattedMoney(sum) : formattedMoney(paymentAmount)}.
                Furthermore, I assert that I am the owner or an authorized
                signer of this bank account.
              </p>
              <p>
                Payment date will show as:{" "}
                <span className="bold">
                  {moment(nearestScheduleDate).format("ll")}
                </span>
              </p>
            </>
          )}
          {accountType ? (
            <>
              <p>
                Type of Account: {accountType === "1" ? "Checking" : "Savings"}
              </p>
              <p>
                Financial Institution Name:{" "}
                <span className="bold">
                  {accountPaymentRequest?.bank_name ||
                    selectedPaymentAccount.BankName}
                </span>
              </p>
              <p>
                Name on Account:{" "}
                <span className="bold">
                  {accountPaymentRequest?.name_on_check}
                </span>
              </p>
              <p>
                Transit/ABA Number:{" "}
                <span className="bold">
                  {accountPaymentRequest?.routing_number ||
                    selectedPaymentAccount.RoutingNumber}
                </span>
              </p>
              <p>
                Last 4 of Account Number:{" "}
                <span className="bold">{accountNumberLastFour()}</span>
              </p>
            </>
          ) : (
            <>
              <p>Type of Account: Debit Card</p>
              <p>
                Name on Account:{" "}
                <span className="bold">
                  {accountPaymentRequest?.name_on_check}
                </span>
              </p>
            </>
          )}
          <p>
            To rescind this authorization and suspend or cancel this payment, or
            the amount withdrawn from your account needs to be different than
            the amount authorized herein, you may call 1 {phoneNumber} during
            the following business hours: {hours}.
          </p>
          <p>
            Authorization Signature:{" "}
            <span className="bold">{accountPaymentRequest?.name_on_check}</span>
          </p>
          <p>
            Date and Time:{" "}
            <span className="bold">{moment(Date()).format("ll")}</span>
          </p>
        </div>
      )}
      <ButtonLink
        text="Download NACHA Authorization Agreement"
        onClickHandler={() => setIsShowingModal(true)}
      />
    </div>
  );
};

AuthAgreementWebOnePay.propTypes = {
  accountType: PropTypes.string,
  selectedPaymentAccount: PropTypes.any,
  setIsShowingModal: PropTypes.func,
};
