import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import PropTypes from "prop-types";

import "./style.scss";
import { OtherAvatarPlaceholderIcon } from "../../../icons/OtherIcons";
import {
  formattingPhoneNumber,
  // formattedSSN,
  formattedMoney,
  unformattedPhoneNumber,
} from "../../../../lib/helpers/formatters";

import { CustomButton } from "../../../ui-kit/CustomButtom";

export const Table = ({ data, headers, tableType, deleteNote, unlockProfile, type }) => {
  // TODO: update this depending on DB structure
  let consumerId = "";
  let agentId = "";
  let tags = [];
  let categories = [];
  let comment = "";

  const handleDeleteNote = (create_at) => {
    deleteNote(create_at);
  }

  const handleUnlockProfile = (id) => {
    unlockProfile(id);
  }

  const returnStatusName = (name) => {
    if (name === "paid_off") {
      return "paid off";
    } else if (name === "on_hold") {
      return "on hold";
    } else {
      return name;
    }
  }

  return (
    <div className={tableType === "wide" ? "table-wide" : "table-wrapper"}>
      <table className="table">
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <tr key={i}>
                {Object.keys(item).map((key, i) => {
                  // Order of items is important
                  switch (key) {
                    case "id":
                      consumerId = item[key];
                      return null;
                    case "client_id":
                      consumerId = item[key];
                      return null;
                    case "fullName":
                      return (
                        <td key={i} className="consumer-full-name">
                          {consumerId === null ? (
                            <span>{item[key]}</span>
                          ) : (
                            <Link
                              to={`/admin/consumers/consumer/${
                                consumerId && consumerId.toString()
                              }`}
                            >
                              {item[key]}
                            </Link>
                          )}
                        </td>
                      );
                    case "full_name":
                      return (
                        <td key={i}>
                          <Link
                            to={`/admin/consumers/consumer/${
                              consumerId && consumerId.toString()
                            }`}
                          >
                            {item[key]}
                          </Link>
                        </td>
                      );
                    case "agentId":
                      agentId = item[key];
                      return null;
                    case "author_id":
                      return (
                        <td key={i}>
                          <span className="heading heading-body-medium">
                            {item[key]}
                          </span>
                        </td>
                      );
                    case "created_at":
                      return (
                        <td key={i} className="created-at">
                          <span className="heading heading-bold-caps">
                            {moment(item[key]).format("lll")}
                          </span>
                          {type !== "disputes" ? (
                            <CustomButton
                              text="Delete Note"
                              buttonId="delete-note-btn"
                              onClickHandler={() => handleDeleteNote(item[key])}
                            />
                          ) : (
                            null
                          )}
                        </td>
                      );
                    case "author_name" :
                      return <td className="author-name" key={i}>{item[key]}</td>;
                    case "data_id":
                      if (type !== "disputes") {
                        return null;
                      } else {
                        return <td key={i}>{item[key]}</td>;
                      }
                    case "tags":
                      tags = item[key];
                      return null;
                    case "categories":
                      categories = item[key];
                      return null;
                    case "responsible_party":
                      return null;
                    case "comment":
                      comment = item[key];
                      return null;
                    case "phone_number":
                      return (
                        <td key={i} className="tags">
                          <div className="d-flex">
                            <h4 className="heading heading-caption-bold">
                              Codes
                            </h4>
                            <div className="tags__container">
                              {tags.map((code, i) => (
                                <span key={i} className="other-tag">
                                  {code}
                                </span>
                              ))}
                            </div>
                          </div>
                          {item.categories ? (
                            <>
                              <div className="d-flex">
                                <h4 className="heading heading-caption-bold">
                                  Type
                                </h4>
                                <div className="tags__container">
                                  <span key={i} className="other-tag">
                                    Comment
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex">
                                <h4 className="heading heading-caption-bold">
                                  Category
                                </h4>
                                <div className="tags__container">
                                  {categories.map((code, i) => (
                                    <span key={i} className="other-tag">
                                      {code}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <div className="d-flex">
                                <h4 className="heading heading-caption-bold">
                                  Responsible Party
                                </h4>
                                <div className="tags__container">
                                  <span key={i} className="other-tag">{item.responsible_party}</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex">
                                <h4 className="heading heading-caption-bold">
                                  Data Id
                                </h4>
                                <div className="tags__container">
                                  <span>{item.data_id}</span>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="d-flex">
                            <h4 className="heading heading-caption-bold">
                              Comment
                            </h4>
                            <div>{comment}</div>
                          </div>
                          {item[key] && item[key].length > 0 && (
                            <div className="d-flex">
                              <h4 className="heading heading-caption-bold">
                                Phone
                              </h4>
                              <div>{formattingPhoneNumber(item[key])}</div>
                            </div>
                          )}
                          {item.conv_id && (
                            <div className="d-flex">
                              <h4 className="heading heading-caption-bold">
                                Conversation Id
                              </h4>
                              <div className="tags__container">
                                <span>{item.conv_id}</span>
                              </div>
                            </div>
                          )}
                        </td>
                      );
                    case "conv_id":
                      return null;
                    case "customer_id":
                      return null;
                    case "agentFullName":
                      if (item.role === 'admin') {
                        return (
                          <td key={i} className="agent-full-name">
                            <div className="d-flex align-items-center">
                              <OtherAvatarPlaceholderIcon />
                              {item[key]}
                            </div>
                          </td>
                        )
                      } else {
                        return (
                          <td key={i} className="agent-full-name">
                            <Link
                              to={`/admin/agents/agent/${agentId.toString()}`}
                            >
                              <div className="d-flex align-items-center">
                                <OtherAvatarPlaceholderIcon />
                                {item[key]}
                              </div>
                            </Link>
                          </td>
                        );
                      }
                    case "account_number":
                      return (
                        <td key={i} className="heading heading-body-medium">
                          {item[key]}
                        </td>
                      );
                    case "SSN":
                      return (
                        <td key={i} className="heading heading-body-medium ssn">
                          {item[key]}
                        </td>
                      );
                    case "cellPhone":
                      return (
                        <td key={i} className="originator">
                          {item[key] &&
                          unformattedPhoneNumber(item[key]).length === 10
                            ? formattingPhoneNumber(item[key])
                            : ""}
                        </td>
                      );
                    case "orginator":
                      return (
                        <td key={i} className="originator">
                          {item[key]}
                        </td>
                      );
                    case "amount":
                      return (
                        <td key={i} className="originator">
                          ${item[key] && formattedMoney(item[key])}
                        </td>
                      )
                    case "status":
                      return (
                        <td key={i} className="status">
                          <div className="status-container">
                            <span
                              className={`status-icon ${
                                item[key] === "paid_off" ? "paid-off" : item[key]
                              }`}
                            ></span>
                            {returnStatusName(item[key])}
                          </div>
                        </td>
                      );
                    case "note":
                      return (
                        <td key={i} className="tags">
                          <div className="d-flex">
                            <h4 className="heading heading-caption-bold">
                              Codes
                            </h4>
                            <div className="tags__container">
                              {item[key][0].map((code) => (
                                <span key={code} className="other-tag">
                                  {code}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="d-flex">
                            <h4 className="heading heading-caption-bold">
                              Comment
                            </h4>
                            <div>{item[key][1]}</div>
                          </div>
                        </td>
                      );
                    case "date":
                      return (
                        <td key={i} className="date-time">
                          {moment(item[key]).subtract(4, "hours").format("lll")}
                        </td>
                      );
                    case "dateOfBirth":
                      return (
                        <td key={i} className="date-time dob">
                          {!item[key]
                            ? ""
                            : moment(item[key]).format("ll")}
                        </td>
                      );
                    case "author":
                      return (
                        <td key={i} className="author">
                          <div className="d-flex align-items-center">
                            <OtherAvatarPlaceholderIcon />
                            {item[key]}
                          </div>
                        </td>
                      );
                    case "changes":
                      return (
                        <td key={i} className="changes">
                          {item[key].map((change, i) => (
                            <div key={i}>
                              <p className="heading heading-body-medium">
                                {change}
                              </p>
                            </div>
                          ))}
                        </td>
                      );
                    case "details":
                      return (
                        <td key={i} className="details">
                          {item[key].map((detail, i) => (
                            <div key={i}>
                              <span className="heading heading-body-medium">
                                {detail}
                              </span>
                            </div>
                          ))}
                        </td>
                      );
                    
                    case "role":
                      return (
                        <td key={i} className="role-block">
                          <div>
                            {item[key]}
                          </div>
                          <CustomButton
                            text="Unlock"
                            buttonId="unlock-profile-btn"
                            onClickHandler={() => handleUnlockProfile(item.agentId)}
                          />
                        </td>
                      );
                    default:
                      return <td key={i}>{item[key]}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  tableType: PropTypes.string,
  deleteNote: PropTypes.func,
  unlockProfile: PropTypes.func,
};
