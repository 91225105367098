import React, { useState, useEffect } from "react";

import { useAlert } from "react-alert";
import { Helmet } from "react-helmet";

import Select from "react-select";

// import { PageHeader } from "../../components/PageHeader";
import { TableContainer } from "../../components/TableContainer";
import { getConsumers } from "../../lib/api/admin";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { TableShowRows } from "../../components/TableContainer/components/TableCustomControls/TableShowRows";
import { SearchContainer } from "../../components/TableContainer/components/TableCustomControls/SearchContainer";
import { TableFilters } from "../../components/TableContainer/components/TableCustomControls/TableFilters";
import { filterItems } from "../../components/TableContainer/components/TableCustomControls/filterItemsData";
// import { TableShowPages } from "../../components/TableContainer/components/TableCustomControls/TableShowPages";
import { formatedConsumersData } from "./helpers";
import ErrorBoundary from "components/ErrorBoundary";
import { useSelector } from "react-redux";
import { getContentBlock } from "lib/api/consumer";

import "./style.scss";

export const Consumers = () => {
  const alert = useAlert();

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [showPerPage, setShowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [consumersAmount, setConsumersAmount] = useState();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [pagination, setPagination] = useState();
  const [consumersData, setConsumersData] = useState([]);
  const [content, setContent] = useState();
  const [selectedSearchType, setSelectedSearchType] = useState();

  const SearchOptions = [
    { value: "first_name", label: "First Name" },
    { value: "last_name", label: "Last Name" },
    { value: "ssn", label: "SSN" },
    { value: "address", label: "Address" },
    { value: "account_number", label: "Account Number" },
    { value: "primary_data_id", label: content?.filter?.referenceNumber ? content?.filter?.referenceNumber : "Reference Number" },
  ];

  const tableHeaders = [
    "Full Name",
    "SSN",
    "Phone Number",
    "Address",
    "Date of Birth",
    content?.table?.originalCreditor ? [content?.table?.originalCreditor] : "Original Creditor",
    "Account #",
    content?.table?.referenceNumber ? [content?.table?.referenceNumber] : "Reference #",
    "Amount",
    "Status",
  ]

  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );

  const fetchConsumers = async (page, search, status, perPage) => {
    setLoading(true);
    let data;

    try {
      data = await getConsumers({
        search_field: selectedSearchType?.value || null,
        search: search || searchText,
        status:
          status || (filterStatus === "paid off" ? "paid_off" : filterStatus),
        current_page: page || currentPage,
        per_page: +perPage || +showPerPage,
      });

      if (data.success === "True") {
        setConsumersData(formatedConsumersData(data.data.page));
        setPagination(data.data.pagination);
        // setConsumersAmount(data.data.clients_number);
      } else {
        alert.error("Error: ", data);
      }
      setLoading(false);
    } catch (e) {
      alert.error("Something went wrong. ", e);
    }
  };

  useEffect(() => {
    fetchConsumers();
    getContent();
  }, []);

  const getContent = async () => {
    const response = await getContentBlock("consumers-table");
    if (response.success === "True") {
      const contentBody = JSON.parse(response.data?.body);
      setContent(contentBody);
      setSelectedSearchType({
        value: "primary_data_id", 
        label: contentBody?.filter?.referenceNumber ? contentBody?.filter?.referenceNumber : "Reference Number"
      })
    }
    setContent({});
  };

  const selectChange = (e) => {
    setSelectedSearchType(e);
  };

  const setCurrentTablePage = async (page) => {
    setCurrentPage(page);
    await fetchConsumers(
      page,
      searchText,
      filterStatus !== " " && filterStatus.split(" ").join("_")
    );
  };

  const handleShowPerPage = async (pages) => {
    setShowPerPage(pages);
    setCurrentPage(1);
    await fetchConsumers(
      1,
      searchText,
      filterStatus !== " " && filterStatus.split(" ").join("_"),
      pages
    );
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
    if (status === "paid off") {
      fetchConsumers(1, searchText, "paid_off");
    } else if (status === "on hold") {
      fetchConsumers(1, searchText, "on_hold");
    } else {
      fetchConsumers(1, searchText, status);
    }
  };

  const handleEnterPressed = () => {
    setCurrentPage(1);
    fetchConsumers(1, searchText);
  };

  return (
    <>
      {selectedSearchType !== undefined ? (
        <div className="consumers">
          <Helmet>
            <title>Customers – {multitenancyCompanyShortName}</title>
          </Helmet>
          <ErrorBoundary>
            <div className="main-container">
              <div className="title">Customers</div>
              {loading ? (
                <LoadingSpinner middleFixed={true} />
              ) : (
                <TableContainer
                  tableHeaders={tableHeaders}
                  data={consumersData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentTablePage}
                  pagesCount={pagination.page_range}
                  tableControls={
                    <div className="table-controls">
                      <SearchContainer
                        handleSearchChange={handleSearchChange}
                        handleEnterPressed={handleEnterPressed}
                        searchText={searchText}
                      />
                      <Select
                        classNamePrefix="custom-select"
                        className="select-search-container"
                        id="search-dropdown"
                        isClearable="true"
                        placeholder="Search by"
                        defaultValue={selectedSearchType}
                        options={SearchOptions}
                        onChange={selectChange}
                      />
                      <TableFilters
                        filterItems={filterItems}
                        filterStatus={filterStatus}
                        handleFilterChange={handleFilterChange}
                      />
                      <TableShowRows
                        showPerPage={showPerPage}
                        handleShowPerPage={handleShowPerPage}
                      />
                      {/* <TableShowPages
                        setCurrentTablePage={setCurrentTablePage}
                        currentPage={currentPage}
                        pagination={pagination}
                        itemsAmount={+consumersAmount}
                        showPerPage={showPerPage}
                      /> */}
                    </div>
                  }
                />
              )}
            </div>
          </ErrorBoundary>
        </div>
      ) : <LoadingSpinner middleFixed={true} />}
    </>
  );
};
